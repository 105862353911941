export default {
    brand: 'shiftech',
    logo: '/images/logo_white_shiftech.svg',
    logoHeight: '38',
    logoSmall: '/images/logo_white_small_shiftech.svg',
    logoSmallHeight: '34',
    logoBlack: '/images/logo_black_shiftech.svg',
    logoBlackSmall: '/images/logo_black_small_shiftech.svg',
    companyName: 'Shiftech'
};
