<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import axios from "axios";

const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('updated_at');
const sortingFieldsOrder = reactive({
    id: 'asc',
    priority: 'asc',
    updated_at: 'desc',
    notifications_bo: 'asc',
    notifications_user: 'asc',
});
watch(url, () => {
    localStorage.setItem('ticketsListUrl', url.value);
    localStorage.setItem('ticketsSortingField', sortingField.value);
    localStorage.setItem('ticketsSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('ticketsSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('ticketsQuery', query.value);
    localStorage.setItem('ticketsFiltersApplied', filtersApplied.value.toString());
});
const isInitialized = ref(false);

onBeforeMount(() => {
    loadSettingsFromLocalStorage();
    loadData(url.value);
});

function loadSettingsFromLocalStorage() {
    const hasStoredSettings = localStorage.getItem('ticketsListUrl') != null;
    if (hasStoredSettings) {
        loadStoredSettings();
    } else {
        setDefaultSettings();
    }
}

function loadStoredSettings() {
    url.value = localStorage.getItem('ticketsListUrl');
    sortingField.value = localStorage.getItem('ticketsSortingField');
    sortingFieldsOrder[sortingField.value] = localStorage.getItem('ticketsSortingFieldsOrder');
    searchLoaded.value = localStorage.getItem('ticketsSearchLoaded') === 'true';
    query.value = localStorage.getItem('ticketsQuery') ?? '';
    filtersApplied.value = localStorage.getItem('ticketsFiltersApplied') === 'true';

    const savedFilters = localStorage.getItem('ticketsFilters');
    if (savedFilters) {
        Object.assign(filters, JSON.parse(savedFilters));
    }
}

function setDefaultSettings() {
    url.value = '/api/tickets?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
}

onMounted(() => {
    isInitialized.value = true;
})

//Get data
const loading = ref(true);
const tickets = ref([]);
function loadData(urlToFetch) {
    tickets.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    tickets.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = searchLoaded.value === true
        ? '/api/search/tickets?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/tickets?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];

    url.value = addFiltersToUrl(url.value);
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('ticketsQuery', query.value);
}, 250);
const performSearch = async ( urlPerformSearch = null ) => {
    tickets.value = [];
    loading.value = true;
    let searchUrl = '/api/search/tickets?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    let urlWithFilters = addFiltersToUrl(searchUrl);

    axios.get(urlWithFilters)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    tickets.value = [];
    let urlWithFilters = addFiltersToUrl('/api/tickets?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]);
    loadData(urlWithFilters);
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToDelete = ref(null);
const indexToDelete = ref(null);
function showModalDelete(id, index) {
    idToDelete.value = id;
    indexToDelete.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalDelete'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function deleteTicket() {
    axios.delete('/api/tickets/' + idToDelete.value)
        .then(() => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalDelete'))
        modaleDelete.hide()
    }
})

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);

const errors = ref(null);
const filters = reactive( {
    user: null,
    status: null,
})
const filtersApplied = ref(true);

function applyFilter(){

    let urlWithFilters = addFiltersToUrl(url.value);
    localStorage.setItem('ticketsFilters', JSON.stringify(filters));
    localStorage.setItem('ticketsFiltersApplied', filtersApplied.value.toString());

    loadData(urlWithFilters);
}

function addFiltersToUrl(url) {
    let modifiedUrl = new URL(url, window.location.origin);

    Object.keys(filters).forEach(key => {
        const value = filters[key];
        if (value !== null && value !== '') {
            modifiedUrl.searchParams.set(key, value);
        } else {
            modifiedUrl.searchParams.delete(key);
        }
    });

    filtersApplied.value = true;

    return modifiedUrl.href;
}

watch(
    () => [filters.user, filters.status],
    () => {
        if (isInitialized.value) {
            filtersApplied.value = false;
        }
    },
    { deep: true }
);
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header border-0">
                    <div class="d-flex align-items-center">
                        <h5 class="card-title mb-0 flex-grow-1">Tickets</h5>
                        <div class="flex-shrink-0">
                            <div class="d-flex flex-wrap gap-2">
                                <router-link :to="{ name : 'tickets.create' }" class="btn btn-secondary add-btn"><i class="ri-add-line align-bottom me-1"></i> Créer un ticket</router-link>
                                <button class="btn btn-success" id="remove-actions" onclick="deleteMultiple()"><i class="ri-delete-bin-2-line"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body border border-dashed border-end-0 border-start-0">
                    <form>
                        <div class="row g-3">
                            <div class="col-xxl-5 col-sm-12">
                                <div class="search-box">
                                    <input type="search" class="form-control search" placeholder="Rechercher..."  v-model="query" @input="search" id="searchTickets">
                                    <i class="ri-search-line search-icon"></i>
                                </div>
                            </div>
                            <form-select-field
                                name="filters.status"
                                label="Sexe"
                                :label-hidden="true"
                                @update:field="filters.status = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.status"
                                bs-class="col-xxl-3 col-sm-4"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                placeholder="Tous les statuts"
                                :liste-options="[
                                    { text: 'Statuts ouverts (A traiter, En cours)', value: 'all_open' },
                                    { text: 'Statuts fermés (Résolu, Fermé)', value: 'all_closed' },
                                    { text: 'Tous les statuts', value: null },
                                    { text: '---', value: null },
                                    { text: 'A traiter', value: 'pending' },
                                    { text: 'En cours', value: 'in_progress' },
                                    { text: 'Résolu', value: 'resolved' },
                                    { text: 'Fermé', value: 'closed' },
                                ]"
                                margin="mb-0"
                            />

                            <form-select-field
                                name="filters.user"
                                label="Sexe"
                                :label-hidden="true"
                                @update:field="filters.user = $event"
                                :errors="errors"
                                :required="true"
                                :data="filters.user"
                                bs-class="col-xxl-3 col-sm-4"
                                :disabled="false"
                                additional-class="bg-light border-light"
                                placeholder="Tous les tickets"
                                :liste-options="[
                                    { text: 'Mes tickets', value: 'my_tickets' },
                                    { text: 'Tickets qui me sont assignés', value: 'assigned_to_me' },
                                    { text: 'Tickets non assignés', value: 'unassigned' },
                                    { text: 'Tous les tickets', value: null },
                                ]"
                                margin="mb-0"
                            />

                            <div class="col-xxl-1 col-sm-4">
                                <button type="button" class="btn w-100" @click.prevent="applyFilter" :class="filtersApplied ? 'btn-dark' : 'btn-warning'">
                                    <i class="ri-equalizer-fill me-1 align-bottom"></i>
                                    Filtrer
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('id')" :class="activeSorting('id')" class="fw-bold pe-4">#ID</a>
                                        </th>
                                        <th class="col-3" scope="col">Titre</th>
                                        <th class="col-1" scope="col">Créé par</th>
                                        <th class="col-1 text-center" scope="col">Statut</th>
                                        <th class="col-1" scope="col">Assigné à</th>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('priority')" :class="activeSorting('priority')" class="pe-4">Priorité</a>
                                        </th>
                                        <th class="col-1 sort">
                                            <a href="#" @click.prevent="changeOrdering('updated_at')" :class="activeSorting('updated_at')" class="pe-4">Dernière MAJ</a>
                                        </th>
                                        <th class="col-1 text-center" scope="col">Notifications</th>
                                        <th class="col-1 text-center" scope="col">Détails</th>
                                        <th class="col-1 text-center" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(ticket, index) in tickets" :key="index">
                                    <td class="fw-medium text-dark">#{{ ticket.data.id }}</td>
                                    <td>{{ ticket.data.title }}</td>
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="flex-shrink-0"><img :src="'/format-image/'+ticket.data.user.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                            <div class="flex-grow-1 ms-2 name">{{ ticket.data.user.data.last_name }} {{ ticket.data.user.data.first_name }}</div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span :class="'badge fs-11 bg-'+ticket.data.status_badge_color+'-subtle  text-'+ticket.data.status_badge_color">{{ ticket.data.status_label_fr }}</span>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center" v-if="ticket.data.assigned_to !== null">
                                            <div class="flex-shrink-0"><img :src="'/format-image/'+ticket.data.assigned_to.data.profile_picture+'?w=32&h=32&fm=webp&fit=crop'" alt="" class="avatar-xs rounded-circle"></div>
                                            <div class="flex-grow-1 ms-2 name">{{ ticket.data.assigned_to.data.last_name }} {{ ticket.data.assigned_to.data.first_name }}</div>
                                        </div>
                                        <span v-else>--</span>
                                    </td>
                                    <td>
                                        <span :class="'badge bg-'+ticket.data.priority_badge_color+'-subtle text-'+ticket.data.priority_badge_color+' badge-border fs-11'">{{ ticket.data.priority_label_fr }}</span>
                                    </td>
                                    <td>{{ ticket.data.updated_at_fr }}</td>
                                    <td class="text-center">
                                        <div v-if="authUser.id === ticket.data.user_id">
                                            <span v-if="ticket.data.notifications_user > 0" class="badge rounded-pill bg-danger fs-11">{{ ticket.data.notifications_user }}</span>
                                        </div>
                                        <div v-else-if="authUser.id === ticket.data.assigned_to_id">
                                            <span v-if="ticket.data.notifications_assigned > 0" class="badge rounded-pill bg-danger fs-11">{{ ticket.data.notifications_assigned }}</span>
                                        </div>
                                        <div v-else-if="ticket.data.assigned_to_id === null && (authUser.role === 'admin' || authUser.role === 'back_office')">
                                            <span v-if="ticket.data.notifications_bo > 0" class="badge rounded-pill bg-danger fs-11">{{ ticket.data.notifications_bo }}</span>
                                        </div>
                                        <div v-else>
                                            <span v-if="ticket.data.notifications_assigned > 0" class="badge rounded-pill bg-danger fs-11">{{ ticket.data.notifications_assigned }}</span>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <router-link :to="{ name: 'tickets.show', params:{ id: ticket.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                    </td>
                                    <td class="text-center">
                                        <!--UPDATE-->
                                        <router-link :to="{ name: 'tickets.edit', params:{ id: ticket.data.id } }" class="text-info" title="Modifier la demande" v-if="ticket.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--Delete-->
                                        <a href="#" class="text-danger" title="Supprimer la demande" @click.prevent="showModalDelete(ticket.data.id, index)" v-if="ticket.can.delete">
                                            <i class="ri-delete-bin-3-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-delete-bin-3-fill fs-17 px-1 text-muted" v-else></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="tickets.length === 0 && !loading">
                                    <td colspan="10" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="10" v-else>Aucun ticket...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="10">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalDelete" title="Supprimer la demande client ?" btn-class="btn-danger" btn-text="Supprimer" @click:button="deleteTicket">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point de supprimer un ticket.</p>
                        </template>
                    </modal>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
