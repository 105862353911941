<template>
    <div class="form-group" :class="bsClass">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <file-pond
            :id="name"
            class="filepond filepond-input-multiple"
            :name="name"
            label-idle='<h5 class="text-muted mb-0">Glissez/déposez les fichiers ici ou cliquez ici.</h5>'
            :allow-multiple="true"
            :files="files"
            @addfile="handleAddFile"
            @removefile="handleRemoveFile"
            :required="required"
        />
        <span class="invalid-feedback" v-html="errorMessage()"></span>
        <span class="form-text text-muted mt-0" v-if="informationContent !== null">
        {{ informationContent }}
    </span>
    </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

// Create component
const FilePond = vueFilePond();
export default {
    name: "FormInputFilePond",
    components: {
        FilePond,
    },
    props : {
        multiple : {
            type: Boolean,
            required: false,
            default: false
        },
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        disabled : {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            required: false,
            default: null
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        },
        accepted : {
            type: String,
            required: false,
            default: ""
        },
        resetFileList : {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            files: []
        }
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            )
        }
    },
    methods: {
        handleAddFile(error, file) {
            if (!error) {
                if (this.multiple){
                    this.files.push(file.file);
                    this.$emit("update:field", this.files)
                } else {
                    this.files = [file.file];
                    this.$emit("update:field", file.file)
                }
            }
        },
        handleRemoveFile(error, file) {
            if (!error) {
                if (this.multiple){
                    this.files = this.files.filter(f => f !== file.file);
                    this.$emit("update:field", this.files)
                } else {
                    this.files = [];
                    this.$emit("update:field", null)
                }
            }
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            return {
                "is-invalid": this.hasError
            }
        }
    },
    watch: {
        resetFileList: {
            immediate: true,
            handler(newVal, oldVal) {
                if (this.resetFileList) this.files = [];
            }
        }
    }
}
</script>

<style scoped>

</style>
