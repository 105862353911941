<script setup>

import Loader from "../../Components/Loader.vue";
import {computed, onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import { useHttpErrorsHandler } from "../../Composables/httpErrorsHandler";
import Pagination from "../../Components/Pagination.vue";
import _ from 'lodash';
import {useToast} from "vue-toast-notification";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import Modal from "../../Components/Modal.vue";
import axios from "axios";

const $toast = useToast();
const { httpErrorsHandler } = useHttpErrorsHandler();

const url = ref('');
const sortingField = ref('created_at');
const sortingFieldsOrder = reactive({
    id: 'asc',
    last_name: 'asc',
    client_status: 'asc',
    last_login: 'asc',
    created_at: 'desc',
});
watch(url, () => {
    localStorage.setItem('clientsListUrl', url.value);
    localStorage.setItem('clientsSortingField', sortingField.value);
    localStorage.setItem('clientsSortingFieldsOrder', sortingFieldsOrder[sortingField.value]);
    localStorage.setItem('clientsSearchLoaded', searchLoaded.value.toString());
    localStorage.setItem('clientsQuery', query.value);
    localStorage.setItem('clientsShowArchived', showArchived.value.toString());
});

onBeforeMount(() => {
    if (localStorage.getItem('clientsListUrl')) {
        url.value = localStorage.getItem('clientsListUrl');
        sortingField.value = localStorage.getItem('clientsSortingField');
        sortingFieldsOrder[sortingField.value] = localStorage.getItem('clientsSortingFieldsOrder');
        searchLoaded.value = localStorage.getItem('clientsSearchLoaded')  === 'true';
        showArchived.value = localStorage.getItem('clientsShowArchived')  === 'true';
        query.value = localStorage.getItem('clientsQuery');
    }else{
        url.value = '/api/clients?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    }
    loadData(url.value);
})

//Get data
const loading = ref(true);
const clients = ref([]);
function loadData(urlToFetch) {
    clients.value = [];
    loading.value = true;
    axios.get(urlToFetch)
        .then(response => {
            if (searchLoaded.value) {
                return performSearch(urlToFetch);
            }
            if(response.data.meta.current_page > response.data.meta.last_page){
                url.value = urlToFetch.replace(/page=\d+/, 'page=' + 1);
                return loadData(url.value);
            }
            paginationAndDataMapping(response.data);
            window.scrollTo(0, 0);
            loading.value = false;
            url.value = urlToFetch;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
//Pagination
const searchLoaded = ref(false);
const savedResponse = ref(null);
const links = ref([]);
const meta = ref([]);
function paginationAndDataMapping(response, mustSave = true) {
    clients.value = response.data;
    links.value = response.links;
    meta.value = response.meta;
    if (mustSave === true && searchLoaded.value === false) {
        savedResponse.value = response;
    }
}

//Ordering table
function changeOrdering(sortingValue) {
    sortingField.value = sortingValue;
    sortingFieldsOrder[sortingField.value] = sortingFieldsOrder[sortingField.value] === 'asc' ? 'desc' : 'asc';

    url.value = searchLoaded.value === true
        ? '/api/search/clients?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/clients?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    if (showArchived.value)
        url.value += '&archived=true';
    loadData(url.value);
}
function activeSorting(sortingValue){
    return sortingField.value === sortingValue ? 'text-secondary' : 'text-dark';
}

//Searching
const query = ref('');
const search = _.debounce(async () => {
    if (query.value.length > 2) {
        await performSearch();
    } else {
        clearSearch();
    }
    localStorage.setItem('clientsQuery', query.value);
}, 250);
const performSearch = async ( urlPerformSearch = null ) => {
    clients.value = [];
    loading.value = true;
    let searchUrl = showArchived.value
        ? '/api/search/clients?archived=true&query=' + query.value + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        : '/api/search/clients?query=' + encodeURIComponent(query.value) + '&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
    if (urlPerformSearch !== null) {
        searchUrl = urlPerformSearch
    }
    axios.get(searchUrl)
        .then(response => {
            paginationAndDataMapping(response.data, false);
            url.value = searchUrl
            searchLoaded.value = true;
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
};
const clearSearch = () => {
    if (savedResponse.value === null) {
        clients.value = [];
        url.value = showArchived.value
            ? '/api/clients?archived=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
            : '/api/clients?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value]
        loadData(url.value);
    } else {
        url.value = '/api/clients?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value] + '&page=' + savedResponse.value.meta.current_page;
        paginationAndDataMapping(savedResponse.value);
    }
    searchLoaded.value = false;
};

const modaleDeleteShown = ref(false);
const idToArchive = ref(null);
const indexToArchive = ref(null);
function showModalArchive(id, index) {
    idToArchive.value = id;
    indexToArchive.value = index;
    const modaleDelete = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalArchive'))
    modaleDelete.show()
    modaleDeleteShown.value = true
}
function archive() {
    axios.delete('/api/clients/' + idToArchive.value)
        .then(() => {
            loadData(url.value);
            const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalArchive'))
            modaleDelete.hide()
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}
onBeforeUnmount(() => {
    if (modaleDeleteShown.value) {
        const modaleDelete = bootstrap.Modal.getInstance(document.getElementById('modalArchive'))
        modaleDelete.hide()
    }
})

const showArchived = ref(false);
function getArchived(){
    loading.value = true;
    showArchived.value = true;
    url.value = '/api/clients?archived=true&sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}

function hideArchived(){
    showArchived.value = false;
    url.value = '/api/clients?sortingField=' + sortingField.value + '&sortingOrder=' + sortingFieldsOrder[sortingField.value];
    loadData(url.value);
}

function restore(id){
    axios.post('/api/clients/' + id + '/restore')
        .then(() => {
            loadData(url.value);
            $toast.success('Client restauré', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
</script>

<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <div class="row g-3">
                        <div class="col-md-4">
                            <div class="search-box search-box-list">
                                <input type="text" class="form-control search" placeholder="Rechercher..." v-model="query" @input="search" id="searchClients">
                                <i class="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <div class="col-md-auto ms-auto">
                            <button type="button" class="btn btn-dark waves-light me-2" @click="getArchived" v-if="!showArchived">
                                <i class="ri-archive-fill me-1 align-bottom"></i> Archives
                            </button>
                            <button type="button" class="btn btn-success waves-light me-2" @click="hideArchived" v-else>
                                <i class="ri-group-fill me-1 align-bottom"></i> Clients
                            </button>
                            <router-link :to="{ name:'clients.create' }" class="btn btn-secondary" v-if="authUser.can && authUser.can.create_client">
                                <i class="ri-add-fill me-1 align-bottom"></i> Ajouter
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div>
                        <div class="table-responsive table-card mb-3">
                            <table class="table align-middle table-nowrap mb-0 table-hover" id="customerTable">
                                <thead class="table-light">
                                <tr>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('id')" :class="activeSorting('id')" class="fw-bold pe-4">#ID</a>
                                    </th>
                                    <th class="col-2 sort">
                                        <a href="#" @click.prevent="changeOrdering('last_name')" :class="activeSorting('last_name')" class="pe-4">Nom</a>
                                    </th>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('client_status')" :class="activeSorting('client_status')" class="pe-4">Type</a>
                                    </th>
                                    <th class="col-2" scope="col">Email</th>
                                    <th class="col-1" scope="col">Téléphone</th>
                                    <th class="col-2" scope="col">Centres</th>
                                    <th class="col-1 sort">
                                        <a href="#" @click.prevent="changeOrdering('created_at')" :class="activeSorting('created_at')" class="pe-4">Date création</a>
                                    </th>
                                    <th class="col-1 text-center" scope="col">Détails</th>
                                    <th class="col-1 text-center" scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                <tr v-for="(client, index) in clients" :key="index">
                                    <td class="fw-medium text-dark">#{{ client.data.id }}</td>
                                    <td>{{ client.data.last_name }} {{ client.data.first_name }}</td>
                                    <td>
                                        <span :class="'badge fs-11 bg-'+client.data.client_status_badge_color+'-subtle  text-'+client.data.client_status_badge_color">{{ client.data.client_status_label_fr }}</span>
                                    </td>
                                    <td>{{ client.data.email }}</td>
                                    <td>{{ client.data.phone_number }}</td>
                                    <td>
                                        <div style="display: flex; align-items: center;" :title="client.data.centers?.map(center => center.data.name).join('\n')">
                                            <i
                                                :class="client.data.centers?.length === 1
                                                    ? 'ri-map-pin-line text-success me-2 fs-15'
                                                    : 'ri-earth-line text-secondary me-2 fs-15'">
                                            </i>
                                            {{ client.data.centers?.length === 1 ? client.data.centers[0].data.name : 'Multi-centres' }}
                                        </div>
                                    </td>
                                    <td>{{ client.data.created_at_fr }}</td>
                                    <td class="text-center">
                                        <router-link :to="{ name: 'clients.show', params:{ id: client.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light btn-sm">Accéder</router-link>
                                    </td>
                                    <td class="text-center">
                                        <!--UPDATE-->
                                        <router-link :to="{ name: 'clients.edit', params:{ id: client.data.id } }" class="text-info" title="Modifier le client" v-if="client.can.update">
                                            <i class="ri-pencil-fill fs-17 px-1"></i>
                                        </router-link>
                                        <i class="ri-pencil-fill fs-17 px-1 text-muted" v-else></i>
                                        <!--ARCHIVE-->
                                        <a href="#" class="text-danger" title="Archiver le client"  data-bs-toggle="modal" data-bs-target="#modalArchive" @click.prevent="showModalArchive(client.data.id, index)" v-if="!showArchived && client.can.delete">
                                            <i class="ri-inbox-archive-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-inbox-archive-fill fs-17 px-1 text-muted" v-if="!showArchived && !client.can.delete"></i>
                                        <!--RESTORE-->
                                        <a href="#" class="text-success" title="Restaurer le client" @click.prevent="restore(client.data.id)" v-if="showArchived && client.can.restore">
                                            <i class="ri-inbox-unarchive-fill fs-17 px-1"></i>
                                        </a>
                                        <i class="ri-inbox-unarchive-fill fs-17 px-1 text-muted"  v-if="showArchived && !client.can.restore"></i>
                                    </td>
                                </tr>
                                <tr class="text-center font-italic text-muted" v-if="clients.length === 0 && !loading">
                                    <td colspan="9" v-if="searchLoaded">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#212529,secondary:#3577f1" style="width:75px;height:75px"></lord-icon>
                                        <p class="mt-2 h5">Aucun résultat</p>
                                        <p class="text-muted mb-0">Désolé, nous n'avons pas pu trouver d'entrées correspondant à : <span class="fst-italic">{{ query }}</span></p>
                                    </td>
                                    <td colspan="9" v-else>Aucun client...</td>
                                </tr>
                                <tr v-if="loading">
                                    <td colspan="9">
                                        <loader></loader>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <pagination
                            @change-page:clicked="loadData($event)"
                            :visible="!loading"
                            :links="links"
                            :meta="meta"
                        ></pagination>
                    </div>

                    <modal id="modalArchive" title="Archiver le client ?" btn-class="btn-danger" btn-text="Archiver" @click:button="archive">
                        <template v-slot:icon>
                            <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#212529,secondary:#f06548" style="width:90px;height:90px" ></lord-icon>
                        </template>
                        <template v-slot:content>
                            <p class="text-muted fs-14 mb-4 pt-1">Vous êtes sur le point d'archiver un client.</p>
                        </template>
                    </modal>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
