export default {
    brand: 'rstronic',
    logo: '/images/logo_white_rstronic.svg',
    logoHeight: '21',
    logoSmall: '/images/logo_white_small_rstronic.svg',
    logoSmallHeight: '11',
    logoBlack: '/images/logo_black_rstronic.svg',
    logoBlackSmall: '/images/logo_black_small_rstronic.svg',
    companyName: 'RStronic'
};
