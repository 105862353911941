<script setup>
import { useRoute } from 'vue-router';
import {computed, inject, onBeforeMount, ref, watch} from "vue";
import {useAuthUserStore} from "../Store/AuthUserStore.js";
import {useToast} from "vue-toast-notification";

const $toast = useToast();
const route = useRoute();
const brandConfig = inject('brandConfig');
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
const isSubscribed = ref(false);

onBeforeMount(() => {
    getNotifications();
});

const hideSidebarOnClickOnMobile = () => {
    const windowSize = document.documentElement.clientWidth;
    if (windowSize <= 767 && document.body.classList.contains("vertical-sidebar-enable")) {
        document.body.classList.remove("vertical-sidebar-enable");
    }
};

const isActiveMenuParent = (parent) => {
    return parent === route.meta.menu.parent;
};

const isActiveMenuChild = (child) => {
    return child === route.meta.menu.child;
};

const notifications = ref([]);
function getNotifications() {
    axios.get('/api/notifications')
        .then(response => {
            notifications.value = response.data.data;
        })
        .catch(errors => {
            console.log(errors);
        })
}

function subscribeToCentersNotificationsChannel() {
    if (authUser.value && Array.isArray(authUser.value.centers)) {
        authUser.value.centers.forEach(center => {
            Echo.channel('notifications_center_' + center.id)
                .listen('CenterNotificationNumberUpdatedEvent', (e) => {
                    getNotifications();
                });
        });
    }
}

function subscribeToUserNotificationsChannel() {
    Echo.channel('notifications_user_' + authUser.value.id)
        .listen('UserNotificationNumberUpdatedEvent', (e) => {
            getNotifications();
        });
}

function subscribeToBackOfficeNotificationsChannel() {
    Echo.channel('notifications_bo')
        .listen('BackOfficeNotificationNumberUpdatedEvent', (e) => {
            getNotifications();
        });
}

function subscribeToEveryoneNotificationsChannel() {
    Echo.channel('notifications_everyone')
        .listen('EveryoneNotificationUpdatedEvent', (e) => {
            getNotifications();
        })
        .listen('NewAppVersionEvent', (e) => {
            $toast.warning('Nouvelle version de l\'application disponible. Veuillez cliquer ici ou vous déconnecter/reconnecter', {
                duration: 0,
                position: 'top',
                dismissible: true,
                pauseOnHover: true,
                closeOnClick: false,
                onDismiss: () => {
                    location.reload();
                }
            });
        });
}



watch(authUser, (newAuthUser) => {
    if (newAuthUser && !isSubscribed.value) {
        subscribeToCentersNotificationsChannel();
        subscribeToUserNotificationsChannel();
        subscribeToEveryoneNotificationsChannel();
        if (newAuthUser.role === 'admin' || newAuthUser.role === 'back_office') {
            subscribeToBackOfficeNotificationsChannel();
        }
        isSubscribed.value = true;
    }
});

</script>

<template>
    <ul class="navbar-nav" id="navbar-nav" data-simplebar>
        <li class="menu-title"><span data-key="t-menu">Menu</span></li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'dashboard' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('dashboard') }">
                <i class="mdi mdi-speedometer"></i> <span data-key="t-starter">Dashboard</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'users.profile' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('profile') }">
                <i class="mdi mdi-account-circle-outline"></i> <span data-key="t-starter">Mon profil</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile" v-if="authUser.centers.length >= 1">
            <router-link :to="{ name: 'centers.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('centers') }" v-if="authUser.centers.length > 1">
                <i class="mdi mdi-office-building-marker-outline"></i> <span data-key="t-starter">
                Mes centres
            </span>
            </router-link>
            <router-link :to="{ name: 'centers.show', params: { id: authUser.centers[0].id } }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('centers') }" v-else>
                <i class="mdi mdi-office-building-marker-outline"></i> <span data-key="t-starter">
                Mon centre
            </span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile" v-if="authUser.role === 'manager'">
            <router-link :to="{ name: 'users.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('users') }">
                <i class="mdi mdi-account-multiple-outline"></i> <span data-key="t-starter">Utilisateurs</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'users.management-team' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('management-team') }">
                <i class="mdi mdi-account-star-outline"></i> <span data-key="t-starter">Team {{ brandConfig.companyName }}</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile" v-if="authUser.role === 'manager'">
            <router-link :to="{ name: 'clients.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('clients') }">
                <i class="mdi mdi-account-group-outline"></i> <span data-key="t-starter">Contacts</span>
            </router-link>
        </li>

        <!--<li class="nav-item" @click="hideSidebarOnClickOnMobile" v-if="authUser.role === 'manager'">-->
        <!--    <router-link :to="{ name: 'client-requests.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('client-requests') }">-->
        <!--        <i class="mdi mdi-car-info"></i> <span data-key="t-starter">Demandes clients</span>-->
        <!--        <span class="badge badge-pill bg-danger" data-key="t-hot" v-if="notifications.notifications_client_requests && notifications.notifications_client_requests > 0">{{ notifications.notifications_client_requests }}</span>-->
        <!--    </router-link>-->
        <!--</li>-->

        <li class="nav-item">
            <a class="nav-link menu-link" href="#client-requests" data-bs-toggle="collapse" role="button" aria-controls="client-requests" :class="{ 'active': isActiveMenuParent('client-requests') }" :aria-expanded="isActiveMenuParent('client-requests')">
                <i class="mdi mdi-car-info"></i> <span data-key="t-layouts">Demandes clients</span>
                <span class="badge badge-pill bg-danger" data-key="t-hot" v-if="notifications.notifications_client_requests && notifications.notifications_client_requests > 0">{{ notifications.notifications_client_requests }}</span>
            </a>
            <div class="collapse menu-dropdown" id="client-requests" :class="{ 'show': isActiveMenuParent('client-requests') }">
                <ul class="nav nav-sm flex-column">
                    <li class="nav-item" @click="hideSidebarOnClickOnMobile">
                        <router-link :to="{ name: 'client-requests.list' }" class="nav-link" :class="{ 'active': isActiveMenuChild('client-requests.list') }" >Toutes les demandes</router-link>
                    </li>
                    <li class="nav-item" @click="hideSidebarOnClickOnMobile">
                        <router-link :to="{ name: 'client-requests.agenda' }" class="nav-link" :class="{ 'active': isActiveMenuChild('client-requests.agenda') }" >Agenda RDV</router-link>
                    </li>
                </ul>
            </div>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile" v-if="authUser.role === 'manager'">
            <router-link :to="{ name: 'calls.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('calls') }">
                <i class="mdi mdi-phone"></i> <span data-key="t-starter">Liste d'appels</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile" >
            <router-link :to="{ name: 'help-articles.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('help-articles') }">
                <i class="mdi mdi-help-circle-outline"></i> <span data-key="t-starter">Centre d'aide</span>
                <span class="badge badge-pill bg-info" v-if="notifications.help_article_available">New</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile" >
            <router-link :to="{ name: 'news.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('news') }">
                <i class="mdi mdi-newspaper"></i> <span data-key="t-starter">Actualités</span>
                <span class="badge badge-pill bg-info" v-if="notifications.news_available">New</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'surveys.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('surveys') }">
                <i class="mdi mdi-thumbs-up-down-outline"></i> <span data-key="t-starter">Sondages</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'tickets.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('tickets') }">
                <i class="mdi mdi-tooltip-plus-outline"></i> <span data-key="t-starter">Tickets</span>
                <span class="badge badge-pill bg-danger" data-key="t-hot" v-if="notifications.notifications_tickets && notifications.notifications_tickets > 0">{{ notifications.notifications_tickets }}</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'social-network-media.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('social-network-media') }">
                <i class="mdi mdi-instagram"></i> <span data-key="t-starter">Biblio. médias</span>
                <span class="badge badge-pill bg-success" v-if="notifications.social_network_media_available">New</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'events.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('events') }">
                <i class="mdi mdi-calendar"></i> <span data-key="t-starter">Évènements</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'chiptuning.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('chiptuning') }">
                <i class="mdi mdi-speedometer"></i> <span data-key="t-starter">Reprogrammations</span>
            </router-link>
        </li>

        <li class="nav-item" @click="hideSidebarOnClickOnMobile">
            <router-link :to="{ name: 'invoices-zoho.list' }" class="nav-link menu-link" :class="{ 'active': isActiveMenuParent('invoices-zoho') }"  v-if="authUser.role === 'manager'">
                <i class="mdi mdi-sticker-text-outline"></i> <span data-key="t-starter">Factures Zoho</span>
            </router-link>
        </li>
    </ul>
</template>

<style scoped>

</style>
