const DefaultMessageEdit = () => import("../Views/DefaultMessage/DefaultMessageEdit.vue");
const DefaultMessageCreate = () => import("../Views/DefaultMessage/DefaultMessageCreate.vue");
const DefaultMessageList = () => import("../Views/DefaultMessage/DefaultMessageList.vue");


export const defaultMessageRoutes = [
    {
        path: "/default-messages/edit/:id",
        name: "default-messages.edit",
        component: DefaultMessageEdit,
        meta: {
            menu: {
                parent: 'default-messages',
                child: null
            },
            breadcrumb: {
                title: "Messages prédéfinis",
                nav: {
                    all: {
                        active: "Modification",
                        inactive: [{
                            title: "Tous les messages",
                            link: {name: 'default-messages.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/default-messages/create",
        name: "default-messages.create",
        component: DefaultMessageCreate,
        meta: {
            menu: {
                parent: 'default-messages',
                child: null
            },
            breadcrumb: {
                title: "Messages prédéfinis",
                nav: {
                    all: {
                        active: "Création",
                        inactive: [{
                            title: "Tous les messages",
                            link: {name: 'default-messages.list'}
                        }]
                    }
                }
            }
        },
    },
    {
        path: "/default-messages",
        name: "default-messages.list",
        component: DefaultMessageList,
        meta: {
            menu: {
                parent: 'default-messages',
                child: null
            },
            breadcrumb: {
                title: "Messages prédéfinis",
                nav: {
                    all: {
                        active: "Tous les messages",
                        inactive: []
                    }
                }
            }
        },
    }
];
