<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {reactive, ref} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormPhoneField from "../../Components/FormPhoneField.vue";
import FormNumericField from "../../Components/FormNumericField.vue";
import FormSelectCountry from "../../Components/FormSelectCountry.vue";
import axios from "axios";

const { httpErrorsHandler } = useHttpErrorsHandler();
const $toast = useToast();
const router = useRouter();

const loading = ref(false)
const form = reactive( {
    name: null,
    legal_name: null,
    legal_form: null,
    registered_capital: null,
    phone_number: null,
    email: null,
    vat_number: null,
    siret_number: null,
    siren_number: null,
    zoho_customer_id: null,
    google_calendar_id: null,
    ringover_webhook_key: null,
    opening_hours: null,
    address: {
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        city: null,
        country: null,
    },
})

const errors = ref(null)
function createCenter() {
    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
            Object.entries(value).forEach(([itemKey, itemValue]) => {
                if (typeof itemValue === 'boolean') {
                    return formData.append(`${key}[${itemKey}]`, itemValue ? 1 : 0);
                }
                if (itemValue !== null && itemValue !== '') {
                    formData.append(`${key}[${itemKey}]`, itemValue);
                }
            });
            return;
        }
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/centers", formData)
        .then(() => {
            $toast.success('Centre ajouté avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.back();
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}
</script>

<template>
<form v-on:submit.prevent="createCenter">
    <div class="row">
        <div class="col-xl-8">
            <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-information-outline me-2 align-middle fs-4"></i>Informations générales</h4>
                </div>
                <div class="card-body">
                    <loader v-if="loading" />
                    <div class="row" v-if="!loading">

                        <form-text-field
                            name="name"
                            label="Nom"
                            :label-hidden="false"
                            @update:field="form.name = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.name"
                            bs-class="col-xl-12"
                        />

                        <form-phone-field
                            name="phone_number"
                            label="Numéro de téléphone"
                            :label-hidden="false"
                            @update:field="form.phone_number = $event"
                            :errors="errors"
                            :required="true"
                            bs-class="col-xl-6"
                            :data="form.phone_number"
                        />

                        <form-text-field
                            name="email"
                            label="Email"
                            :label-hidden="false"
                            @update:field="form.email = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.email"
                            bs-class="col-xl-6"
                            type="email"
                        />

                        <form-text-field
                            name="legal_name"
                            label="Dénomination sociale"
                            :label-hidden="false"
                            @update:field="form.legal_name = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.legal_name"
                            bs-class="col-xl-6"
                            information-content="Obligatoire pour la fiche de travail."
                        />

                        <form-text-field
                            name="legal_form"
                            label="Forme juridique"
                            :label-hidden="false"
                            @update:field="form.legal_form = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.legal_form"
                            bs-class="col-xl-3"
                            :placeholder="`SARL, SAS, ...`"
                            information-content="Obligatoire pour la fiche de travail."
                        />

                        <form-numeric-field
                            name="registered_capital"
                            label="Capital social"
                            :label-hidden="false"
                            @update:field="form.registered_capital = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.registered_capital"
                            information-content="Obligatoire pour la fiche de travail."
                            bs-class="col-xl-3"
                            :min="0"
                            :step="1"
                        />

                        <form-text-field
                            name="vat_number"
                            label="Numéro de TVA"
                            :label-hidden="false"
                            @update:field="form.vat_number = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.vat_number"
                            bs-class="col-xl-4"
                            type="text"
                        />

                        <form-text-field
                            name="siret_number"
                            label="Numéro de SIRET"
                            :label-hidden="false"
                            @update:field="form.siret_number = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.siret_number"
                            bs-class="col-xl-4"
                            type="text"
                        />

                        <form-text-field
                            name="siren_number"
                            label="Numéro de SIREN"
                            :label-hidden="false"
                            @update:field="form.siren_number = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.siren_number"
                            bs-class="col-xl-4"
                            type="text"
                        />

                        <form-text-field
                            name="opening_hours"
                            label="Horaires d'ouverture"
                            :label-hidden="false"
                            @update:field="form.opening_hours = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.opening_hours"
                            bs-class="col-xl-12"
                            type="text"
                            information-content="Les horaires sont affichés dans le tchat du client."
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-information-outline me-2 align-middle fs-4"></i>Autres</h4>
                </div>
                <div class="card-body">
                    <loader v-if="loading" />
                    <div class="row" v-if="!loading">

                        <form-text-field
                            name="zoho_customer_id"
                            label="ID client Zoho"
                            :label-hidden="false"
                            @update:field="form.zoho_customer_id = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.zoho_customer_id"
                            bs-class="col-xl-12"
                            type="text"
                        />

                        <form-text-field
                            name="google_calendar_id"
                            label="ID Google Agenda"
                            :label-hidden="false"
                            @update:field="form.google_calendar_id = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.google_calendar_id"
                            bs-class="col-xl-12"
                            type="text"
                        />

                        <form-text-field
                            name="ringover_webhook_key"
                            label="Clé Webhook Ringover"
                            :label-hidden="false"
                            @update:field="form.ringover_webhook_key = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.ringover_webhook_key"
                            bs-class="col-xl-12"
                            type="text"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-12">
            <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-map-marker-outline me-2 align-middle fs-4"></i>Adresse</h4>
            </div>
            <div class="card-body">
                <loader v-show="loading" />
                <div class="row" v-show="!loading">
                    <form-text-field
                        name="address.address_line_1"
                        label="Adresse"
                        :label-hidden="false"
                        @update:field="form.address.address_line_1 = $event"
                        :errors="errors"
                        :required="true"
                        :data="form.address.address_line_1"
                        bs-class="col-xl-12"
                    />

                    <form-text-field
                        name="address.address_line_2"
                        label="Complément d'adresse"
                        :label-hidden="false"
                        @update:field="form.address.address_line_2 = $event"
                        :errors="errors"
                        :required="false"
                        :data="form.address.address_line_2"
                        bs-class="col-xl-12"
                    />

                    <form-text-field
                        name="address.postal_code"
                        label="Code postal"
                        :label-hidden="false"
                        @update:field="form.address.postal_code = $event"
                        :errors="errors"
                        :required="true"
                        :data="form.address.postal_code"
                        bs-class="col-xl-4"
                    />

                    <form-text-field
                        name="address.city"
                        label="Ville"
                        :label-hidden="false"
                        @update:field="form.address.city = $event"
                        :errors="errors"
                        :required="true"
                        :data="form.address.city"
                        bs-class="col-xl-4"
                    />

                    <form-select-country
                        name="address.country"
                        label="Pays"
                        :label-hidden="false"
                        @update:field="form.address.country = $event"
                        :errors="errors"
                        :required="true"
                        :data="form.address.country"
                        bs-class="col-xl-4"
                    />

                    <div class="d-flex gap-2 justify-content-end">
                        <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                        <button type="submit" class="btn btn-secondary">Valider</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</form>
</template>

<style scoped>

</style>
