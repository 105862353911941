<template>
    <div class="mb-3" :class="bsClass">
        <label :for="name" class="form-label" v-if="!labelHidden">
            {{ label }}
            <span class="text-danger" v-if="required"> *</span>
        </label>
        <multiselect
            v-model="centersSelected"
            :options="centers"
            :multiple="true"
            :close-on-select="false"
            :preserve-search="true"
            :placeholder="placeholder"
            label="name"
            track-by="id"
            :preselect-first="true"
            :hide-selected="false"
            select-label=""
            selected-label="Sélectionné"
            deselect-label=""
            :allow-empty="allowEmpty"
        >
        </multiselect>
        <span class="error-message" v-html="errorMessage()"></span>
        <span class="form-text text-muted" v-if="informationContent !== null">
            {{ informationContent }}
        </span>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "FormSelectCenters",
    props: {
        allowEmpty : {
            type: Boolean,
            required: false,
            default: true
        },
        name : {
            type: String,
            required: true
        },
        label : {
            type: String,
            required: true
        },
        labelHidden : {
            type: Boolean,
            required: false,
            default: false
        },
        required : {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        informationContent : {
            type: String,
            required: false,
            default: null
        },
        reference : {
            type: String,
            required: false,
            default: 'multiple-select'
        },
        centersPreSelected: {
            type: Array,
            required: false,
            default: null
        },
        errors: {
            required: true
        },
        bsClass: {
            type: String,
            required: false,
            default: "col-md-12"
        }
    },
    data() {
        return {
            centersSelected : [],
            centers: [],
            loading: false,
        }
    },
    mounted() {
        this.getCenters();
    },
    methods: {
        getCenters() {
            axios.get('/api/centers?sortingField=name&sortingOrder=ASC&all')
                .then(response => {
                    response.data.data.forEach(
                        center => this.centers.push({
                            id: center.data.id,
                            name: center.data.name
                        })
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearAll () {
            this.centersSelected = []
        },
        clearErrors: function() {
            if (this.hasError) {
                this.errors[this.name] = null;
            }
        },
        errorClassObject: function() {
            return {
                "is-invalid": this.hasError
            }
        },
        errorMessage: function() {
            if (this.hasError) {
                return this.errors[this.name][0];
            }
        }
    },
    computed: {
        hasError() {
            return (
                this.errors &&
                this.errors[this.name] &&
                this.errors[this.name].length > 0
            );
        }
    },
    watch: {
        centersSelected: function(){
            if( this.centersSelected === null) {
                return this.$emit("update:field", null);
            } else {
                this.clearErrors(this.name);
                return this.$emit("update:field", this.centersSelected);
            }
        },
        centersPreSelected: {
            immediate: true,
            handler() {
                if (this.centersPreSelected !== null && this.centersPreSelected.length > 0) {
                    this.centersPreSelected.forEach(center => {
                        this.centersSelected.push({
                            id: center.data.id,
                            name: center.data.name
                        })
                    });
                    this.$emit("update:field", this.centersSelected);
                }
            }
        }
    }
}
</script>

<style>

</style>
