import './bootstrap';
import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import router from "./Router/router"
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import vClickOutside from "click-outside-vue3"
import Multiselect from 'vue-multiselect'
import ToastPlugin from 'vue-toast-notification'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import * as Sentry from "@sentry/vue";

//Layouts
import TopBar from "./Layouts/TopBar.vue";
import SideBar from "./Layouts/SideBar.vue";
import Breadcrumb from "./Layouts/Breadcrumb.vue";
import FooterLayout from "./Layouts/Footer.vue";
//CSS
// import 'vue-toast-notification/dist/theme-bootstrap.css';

//Languages
import fr from './Locales/fr.json'
import en from './Locales/en.json'
const i18n = createI18n({
    legacy: false,
    locale: 'fr',
    fallbackLocale: 'en',
    messages: { fr, en },
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

const brand = import.meta.env.VITE_APP_CURRENT_BRAND;
import(`./Configs/${brand}.js`).then((brandConfig) => {
    const app = createApp({})

    if (import.meta.env.VITE_APP_ENV === 'production') {
        Sentry.init({
            app : app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 0.05,
            tracePropagationTargets: [import.meta.env.VITE_SENTRY_TRACE_PROPAGATION_TARGETS],
            // Session Replay
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1.0,
        });
    }

    app.provide('brandConfig', brandConfig.default);

    app.use(router)
        .use(pinia)
        .use(i18n)
        .use(vClickOutside)
        .use(ToastPlugin)
        .use(VueViewer)
        .component('TopBar', TopBar)
        .component('SideBar', SideBar)
        .component('Breadcrumb', Breadcrumb)
        .component('FooterLayout', FooterLayout)
        .component("v-select", vSelect)
        .component('multiselect', Multiselect);

    router.isReady().then(() => app.mount('#layout-wrapper'));
});

